
.mb-60{
    margin-bottom: 60px;
}

.menuListBox{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}
.menuListBox .menuListItem {
    width: 300px;
    box-sizing: border-box;
    
    box-shadow: 0px 5px 10px 0px rgba(143, 142, 142, 0.986);
    margin: 31px;
    border-radius: 10px;
   

}

.menuListBox .menuListItem .menuListItemContent{
    padding: 20px;
    text-align: center;
}
.menuListBox .menuListItem .menuListItemContent .price{
    font-weight: bold;
    color: rgb(255, 0, 0);
    font-style: italic;
    font-size: 20px;
}
.menuListBox .menuListItem:hover{
    transform: scale(105%);
    transition: all ease-in 250ms;
    cursor: pointer;
}
.menuListBox .menuListItem img{
    height: 160px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;


}

.title{
    text-align: center;
    font-size: 50px;
}