


.header{
    box-sizing: border-box;
    width: 100%;
    background-color: rgb(132, 31, 31);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 100px;
  }
  
   .headerLogo img{
    width: 60px;
  
  } 

  
  
  .headerNav ul{
    list-style: none;
  }
  
  .headerNav ul a{
    text-decoration: none;
    user-select: none;
  }
  
  .headerNav ul a.active{
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  
  .headerNav ul li{
    display: inline;
    margin-left: 40px;
    font-size: 18px;
    color: white;
  }