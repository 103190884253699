

.mlr-100{
    margin-right: 100px;
    margin-left: 100px;
}

.aboutBox p{
    text-align: center;
    margin-right:  50px;
    margin-left: 50px;
    


}

.contactBox{
    display: flex;
}

.contantContentBox{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    

}

.contantContentBox form{
    
    display: flex;
    flex-direction: column;
    align-items: start;
}

.contantContentBox form,.contantContentBox form input[type="text"] ,.contantContentBox textarea{
    width: 100%;
}

.contantContentBox form input[type="text"] , .contantContentBox textarea{
    border: 1px solid rgb(206, 205, 205);
    height: 30px;
    border-radius: 5px;
}

.contantContentBox form label{
    margin-bottom: 10px;
    margin-top: 10px;
}

.contantContentBox form a{
    margin-top: 10px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    background-color: rgb(253, 180, 20) ;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 5px 10px 0px rgba(253, 179, 20, 0.581);
    cursor: pointer;
    
}

