
.mb-20{
    margin-bottom: 20px;
}

.mt-20{
    margin-top: 20px;
}

.footer{
    color: #fff;
    background-color: rgb(132, 31, 31);
    text-align: center;
    padding: 20px 0px ;
    

}


.footer .socialLogo ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer .socialLogo ul li{
    display: inline;

}

.footer .socialLogo svg{
    border: 2px solid white;
    border-radius: 50%;
    padding: 5px;
    width: 25px;
    height: 25px;
    margin-right: 20px;

}

.footer .socialLogo svg:hover{
    background-color: rgb(253, 180, 20) ;
    cursor: pointer;
}


.footer .copyright a{
    text-decoration: none;
    color: #fff;
    
}

.footer .copyright a:hover{
    background-color: rgb(253, 180, 20) ;
    

}




